/*
 * jQuery Basic Table
 * Author: Jerry Low
 */

table.bt thead,
table.bt tbody th {
  display: none;
}

table.bt tfoot th,
table.bt tfoot td,
table.bt tbody td {
  border: none;
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  vertical-align: top;

  /* IE 9 */
  float: left\9;
  width: 100%\9;
}

table.bt tfoot th::before,
table.bt tfoot td::before,
table.bt tbody td::before {
  content: attr(data-th) ": ";
  display: inline-block;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  font-weight: bold;
  width: 6.5em;
}

table.bt tfoot th.bt-hide,
table.bt tfoot td.bt-hide,
table.bt tbody td.bt-hide {
  display: none;
}

table.bt tfoot th .bt-content,
table.bt tfoot td .bt-content,
table.bt tbody td .bt-content {
  vertical-align: top;
}

.bt-wrapper.active {
  max-height: 310px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

table.bt.bt--no-header tfoot td::before,
table.bt.bt--no-header tbody td::before {
  display: none;
}
